import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ResearchArticlePreview from "../../components/researcharticlepreview"
import { BsSearch } from "react-icons/bs"
import { AiOutlinePlusCircle } from "react-icons/ai"
const DocsPage = () => (
  <Layout>
    <SEO title="Documentation - PCS" description="" lang="en" meta={[]} />
    <div className="banner-image secondary docs">
      <div className="hero-text">
        <h1>PCS Documenation</h1>
        <h3>View documentation and published research from PCS.</h3>
      </div>

      {/* Search Section */}
      <div className="search-div">
        <BsSearch />
        <input placeholder="Search Publications" />
      </div>

      {/* Link Research */}
      <section className="research-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <h3>
                <b>Filter By</b>
              </h3>
              <div className="filter-div">
                <h3>Year Published</h3>
                <AiOutlinePlusCircle />
              </div>
            </div>
            <div className="col-lg-6">
              <h3>
                <b>All Publications</b>
              </h3>
              <ResearchArticlePreview
                link="/pcs/articles/productive-computational-science-platform"
                title="PCS: A Productive Computational Science Platform"
                description="Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem nihil voluptas deleniti fugit distinctio eligendi ut maxime tempora vitae itaque. Dolor voluptatem laudantium officiis expedita soluta tenetur animi nihil quisquam."
                authors={[
                  "Dave Ojika",
                  "Ann Gordon-Ross",
                  "Herman Lam",
                  "Shinjae Yoo",
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
)

export default DocsPage
