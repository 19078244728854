import { Link } from "gatsby";
import React from "react";

interface Props {
  title: string;
  authors: Array<string>;
  description: string;
  link: string;
}

const ResearchArticlePreview = ({
  title,
  authors,
  description,
  link,
}: Props) => {
  return (
    <Link className="research-article" to={link}>
      <h3>{title}</h3>
      <div className="authors">
        {authors.map((author, i) => {
          if (i !== authors.length - 1) {
            return <span key={author}>{author},</span>;
          }
          return <span key={author}>{author}...</span>;
        })}
      </div>
      <p>{description}..</p>
    </Link>
  );
};

export default ResearchArticlePreview;
